<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { getBagOverview } from "@/services/api/location.api";

const { formatDateCustom } = useDateTimeFormatter();
const emits = defineEmits(["selected"]);

setInteractionMode("eager");

const bag = ref({
  id: undefined,
  postalCode: undefined,
  houseNumber: undefined,
  houseNumberAddition: undefined,
});
const bagLoading = ref(false);
const bagOverview = ref([]);
const bagSelected = ref([]);

const headers = computed(() => {
  let h = [{ text: "", value: "actions", sortable: false, width: 20 }];
  h.push(
    { text: "Pand", value: "buildingId" },
    { text: "Verblijfsobject", value: "residenceId" },
    { text: "Bouwjaar", value: "constructionDate" },
    { text: "Type", value: "typeDescription" },
    { text: "GO", value: "go" },
    { text: "Adr. Type", value: "addressTypeDescription" },
    { text: "Adres", value: "composedAddress" },
    { text: "Postcode", value: "postalCode" },
    { text: "Woonplaats", value: "city" }
  );
  return h;
});

watch(
  () => bagSelected.value,
  () => {
    emits("selected", [...bagSelected.value]);
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

const searchBag = ref(null);

async function onSearchBag() {
  const valid = await searchBag.value.validate();
  if (valid) {
    bagLoading.value = true;
    bagOverview.value = [];
    bagSelected.value = [];
    console.log("BagSearch onSearchBag", bag.value);
    const response = await getBagOverview(bag.value.id, bag.value.postalCode, bag.value.houseNumber, bag.value.houseNumberAddition);
    if (response && !response.error) {
      console.log("BagSearch response", response);
      if (response.data) {
        bagOverview.value = response.data.map((el) => ({ ...el, key: `${el.buildingId}:${el.residenceId}:${el.numberId}` }));
      }
    } else {
      proxy.$toaster.error("Ophalen van de BAG gegevens is mislukt! " + response.error);
    }
    bagLoading.value = false;
  }
}

function itemRowBackground(item) {
  return item.residenceId ? (item.primaryAddress ? "bagResidence" : "bagResidenceAlternative") : "bagBuilding";
}
</script>

<template>
  <ValidationObserver ref="searchBag">
    <v-form>
      <v-container class="boxed" fluid>
        <header>BAG</header>
        <v-row>
          <v-col>
            <ValidationProvider name="BagId" rules="numeric" v-slot="{ errors }">
              <v-text-field hide-details="auto" label="ID" outlined persistent-placeholder v-model="bag.id" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="auto" align-self="center"> of </v-col>
          <v-col>
            <ValidationProvider name="Postcode" rules="postalcode" v-slot="{ errors }">
              <v-text-field hide-details="auto" label="Postcode" outlined persistent-placeholder v-model="bag.postalCode" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-text-field hide-details="auto" label="Huisnummer" outlined persistent-placeholder v-model="bag.houseNumber"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field hide-details="auto" label="Toevoeging" outlined persistent-placeholder v-model="bag.houseNumberAddition"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto" class="form-group">
            <v-btn class="primary" v-on:click="onSearchBag()">
              <v-icon dark left>mdi-magnify</v-icon>
              Zoek
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              class="bag"
              dense
              show-select
              disable-sort
              disable-pagination
              v-model="bagSelected"
              :headers="headers"
              hide-default-footer
              :items="bagOverview"
              :loading="bagLoading"
              :item-class="itemRowBackground"
              item-key="key"
            >
              <template #[`header.data-table-select`]></template>

              <template #[`item.data-table-select`]="{ isSelected, select, item }">
                <template v-if="!item.residenceId || item.primaryAddress">
                  <v-simple-checkbox v-ripple color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
              </template>

              <template v-slot:[`item.constructionDate`]="{ item }">
                {{ formatDateCustom(item.constructionDate, "YYYY") }}
              </template>
              <template v-slot:[`item.buildingId`]="{ item }">
                <template v-if="!item.residenceId"> {{ item.buildingId }}</template>
              </template>
              <template v-slot:[`item.residenceId`]="{ item }">
                <template v-if="item.primaryAddress"> {{ item.residenceId }}</template>
              </template>
              <template v-slot:[`item.go`]="{ item }">
                <template v-if="!item.residenceId || item.primaryAddress"> {{ item.go }}</template>
              </template>
              <template v-slot:[`item.typeDescription`]="{ item }">
                <template v-if="!item.residenceId || item.primaryAddress"> {{ item.typeDescription }}</template>
              </template>
              <template v-slot:[`item.addressTypeDescription`]="{ item }">
                <template> {{ item.addressTypeDescription }}</template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </ValidationObserver>
</template>
